<template>        
    <v-row dense>
        <v-col v-if="label" class="d-flex align-center">
            <div v-if="label">{{ label }}</div>
        </v-col>        
        <v-spacer v-if="label || rightAlign" />
        <v-col cols="auto" class="d-flex align-center">
            <v-btn-toggle                              
                :color="buttonColor"                
                v-model="toggle" 
                mandatory  
                :dark="!disabled && dark"
                dense                    
                rounded                
                :light="light"           
                @change="update">
                <v-btn :title="falseText" small :disabled="disabled"><v-icon v-if="falseIcon">{{ falseIcon }}</v-icon><span v-else>{{ falseText }}</span></v-btn>
                <v-btn :title="trueText" small :disabled="disabled"><v-icon v-if="trueIcon">{{ trueText }}</v-icon><span v-else>{{ trueText }}</span></v-btn>
            </v-btn-toggle> 
            <slot/>                 
        </v-col>            
    </v-row>        
</template>

<script>
export default {
    name: 'BT-Toggle',
    data: () => {
        return {
            toggle: 0
        }
    },    
    created() {       
        this.toggle = this.value === true ? 1 : 0;
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        trueText: {
            type: String,
            default: 'On'
        },
        trueIcon: {
            type: String,
            default: null
        },
        falseText: {
            type: String,
            default: 'Off'
        },
        falseIcon: {
            type: String,
            default: null
        },
        dark: {
            type: Boolean,
            default: true
        },
        light: {
            type: Boolean,
            default: false
        },
        useIcons: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            default: 'primary darken-2'
        },
        rightAlign: {
            type: Boolean,
            default: true
        },
        buttonColor: {
            type: String,
            default: 'primary lighten-4'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        update() {
            this.$emit('input', this.toggle === 0 ? false : true);
            this.$emit('change', this.toggle === 0 ? false : true);
        }
    }
}
</script>